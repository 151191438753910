import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { cn } from 'helpers/classnames';
import { MartyContext } from 'utils/context';
import { getScreenSize } from 'helpers/HtmlHelpers';
import { track } from 'apis/amethyst';
import { loadFromLocalStorage, saveToLocalStorage } from 'helpers/localStorageUtilities';

import styles from 'styles/components/common/tooltip.scss';
class Tooltip extends Component {
  state = {
    visible: false,
    isMobileScreen: false,
    isToolTipSeen: false
  };

  componentDidMount() {
    const { showOnceStorageKey } = this.props;
    if (showOnceStorageKey) {
      const localStorageList = loadFromLocalStorage(showOnceStorageKey, {});
      const isToolTipSeenLS = localStorageList[showOnceStorageKey];

      this.setState({ isToolTipSeen: isToolTipSeenLS });

      if (!isToolTipSeenLS) {
        localStorageList[showOnceStorageKey] = true;
        saveToLocalStorage(showOnceStorageKey, localStorageList);
      }
    }
  }

  componentDidUpdate() {
    const { direction } = this.props;
    const isMobileScreen = getScreenSize() === 'mobile';
    if (isMobileScreen !== this.state.isMobileScreen && direction !== 'bottom') {
      this.setState({ isMobileScreen });
    }
  }

  setVisibility = (visible = false) => {
    this.setState({ visible });
  };

  hide = () => {
    const { setIsContentVisible } = this.props;
    setIsContentVisible && setIsContentVisible(false);
    this.trackOpenCloseEvents(false);
    this.state.isMobileScreen
      ? setTimeout(this.setVisibility, 0) /* If the tooltip has a link, we must wait
                                             for the next batch of events.
                                             Otherwise the tooltip will be hidden
                                             before the link click is registered.
                                             At the time of implementing this,
                                             attempting to use requestAnimationFrame()
                                             broke dismissing the tooltip via tapping the overlay */
      : this.setVisibility(false);
  };

  trackOpenCloseEvents = debounce(opened => {
    const { eventData } = this.props;

    if (!eventData) {
      return;
    }

    track(() => [eventData.event, { opened, closed: !opened, ...eventData.data }]);
  }, 250);

  makeDirectionClassName = (direction, contentTopClassName) => {
    switch (direction) {
      case 'left':
        return styles.left;
      case 'right':
        return styles.right;
      case 'bottom':
        return styles.bottom;
      case 'top':
        return contentTopClassName;
      default:
        return '';
    }
  };

  show = () => {
    this.setVisibility(true);
    this.trackOpenCloseEvents(true);
  };

  onMouseEnter = () => {
    const { onMouseEnter: handleMouseEnter } = this.props;
    handleMouseEnter && handleMouseEnter();
    this.show();
  };

  onMouseLeave = () => {
    const { onMouseLeave: handleMouseLeave } = this.props;
    handleMouseLeave && handleMouseLeave();
    this.hide();
  };

  makeTooltipContent = ({ contentTarget = 'default' } = {}) => {
    const {
      props: { tooltipClassName, contentClassName, content, simple, direction, sponsored, clickOpen, contentTopClassName },
      state: { isMobileScreen, visible },
      context: { testId }
    } = this;

    const isContentForMobile = contentTarget === 'mobile';
    const shouldRenderContents = isContentForMobile ? isMobileScreen : !isMobileScreen;

    return (
      (clickOpen || visible) &&
      shouldRenderContents && (
        <div
          data-test-id={testId('tooltipContent')}
          className={cn(
            styles.tooltip,
            {
              [styles.simpleTooltip]: simple,
              [styles.sponsored]: sponsored,
              [styles.forMobile]: isContentForMobile
            },
            this.makeDirectionClassName(direction, contentTopClassName),
            tooltipClassName
          )}
        >
          <div className={cn(styles.content, contentClassName)}>{content}</div>
        </div>
      )
    );
  };

  handleKeyDown = e => {
    const { key } = e;

    const {
      state: { visible }
    } = this;

    switch (key) {
      case 'Escape': {
        if (visible) {
          this.onMouseLeave();
        }
        break;
      }
    }
  };

  makeInteractive = () => {
    const {
      wrapperClassName,
      children,
      clickOpen,
      isTabbable,
      content,
      useOverlay = true,
      clickToOpen,
      ariaLabel = null,
      onClick,
      dataTestId = null,
      isLinkCopied
    } = this.props;
    const {
      state: { visible },
      context: { testId }
    } = this;
    let htmlContent = null;

    isLinkCopied && setTimeout(() => this.hide(), 2500);

    if (clickOpen) {
      htmlContent = (
        <>
          {useOverlay && (
            <div
              className={cn(styles.overlay, {
                [styles.activateOverlay]: clickOpen || visible
              })}
              onMouseEnter={this.onMouseLeave}
            ></div>
          )}
          <div className={cn(styles.wrapper, wrapperClassName)} role="dialog" aria-labelledby="feedBackTitle" aria-describedby="feedBackTitle">
            {children}
            {this.makeTooltipContent()}
          </div>
        </>
      );
    } else if (clickToOpen) {
      htmlContent = (
        <>
          {useOverlay && (
            <div
              className={cn(styles.overlay, {
                [styles.activateOverlay]: visible
              })}
              onMouseEnter={this.onMouseLeave}
            ></div>
          )}
          <button
            aria-label={ariaLabel}
            type="button"
            data-test-id={testId(dataTestId)}
            onKeyDown={this.handleKeyDown}
            className={cn(styles.wrapper, styles.tooltipButton, wrapperClassName)}
            onClick={() => onClick(this.show) ?? this.show}
            onFocus={() => onClick(this.show) ?? this.show}
            onBlur={e => (!e.currentTarget.contains(e.relatedTarget) ? this.hide() : this.show())}
          >
            {children}
            {this.makeTooltipContent()}
          </button>
        </>
      );
    } else {
      htmlContent = (
        <>
          {useOverlay && (
            <div
              className={cn(styles.overlay, {
                [styles.activateOverlay]: visible
              })}
              onMouseEnter={this.onMouseLeave}
            ></div>
          )}
          <div
            className={cn(styles.wrapper, wrapperClassName)}
            onMouseOut={!isTabbable ? this.onMouseLeave : null}
            onBlur={!isTabbable ? this.onMouseLeave : null}
            onMouseOver={!isTabbable ? this.onMouseEnter : null}
            onFocus={!isTabbable ? this.onMouseEnter : null}
            data-test-id={testId('tooltipWrapper')}
          >
            {children}
            {this.makeTooltipContent()}
          </div>
        </>
      );
    }

    if (isTabbable) {
      return (
        <button
          aria-label={content}
          type="button"
          className={styles.tooltipButton}
          onKeyDown={this.handleKeyDown}
          onFocus={this.onMouseEnter}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onBlur={this.onMouseLeave}
        >
          {htmlContent}
        </button>
      );
    }

    return htmlContent;
  };

  render() {
    const { isToolTipSeen } = this.state;
    const { mobileWrapper, mobileClass } = this.props;

    if (isToolTipSeen) {
      return null;
    }

    return (
      <MartyContext.Consumer>
        {context => {
          this.context = context;

          return (
            <>
              {this.makeInteractive()}
              <div className={cn(styles.mobileContainer, mobileWrapper)}>
                <div className={cn(styles.mobileEncompass, mobileClass)}>{this.makeTooltipContent({ contentTarget: 'mobile' })}</div>
              </div>
            </>
          );
        }}
      </MartyContext.Consumer>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  wrapperClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipContent: PropTypes.string,
  contentClassName: PropTypes.string,
  simple: PropTypes.bool
};

export default Tooltip;

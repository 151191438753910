import { ProductCarousel } from '@mweb/zappos-ui/ProductCarousel';
import type { ProductCarouselProps } from '@mweb/zappos-ui/ProductCarousel';

import useMartyContext from 'hooks/useMartyContext';

interface ProductDetailCarouselProps {
  slides: React.ReactNode[];
  title: string | undefined;
  overrides?: ProductCarouselProps['overrides'];
  dataTestId?: string;
}

export const ProductDetailCarousel = (props: ProductDetailCarouselProps) => {
  const { title, slides, overrides, dataTestId } = props;
  const { testId } = useMartyContext();

  return (
    <section className="flex flex-col gap-4" data-test-id={testId(dataTestId)}>
      {title && <h2 className="grow text-xl/[1.2] font-semibold lg:text-2xl/[1.2]">{title}</h2>}

      <ProductCarousel options={{ slidesToScroll: 1, skipSnaps: true }} slides={slides} overrides={overrides} />
    </section>
  );
};

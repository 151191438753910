import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { CurrencyObj } from 'helpers/DataFormatUtils';
import ProductUtils from 'helpers/ProductUtils';

import css from 'styles/components/common/card/cardPrice.scss';

interface CardPriceProps {
  price: CurrencyObj;
  msrp: CurrencyObj;
  onSale: boolean;
  prefix?: string;
}

const CardPrice = (props: CardPriceProps) => {
  const {
    testId,
    marketplace: {
      search: { showPercentOff },
      pdp: { percentOffText }
    }
  } = useMartyContext();
  const { price, msrp, onSale, prefix } = props;
  const isOnSale = onSale && !prefix;
  return (
    <>
      <dt>Price</dt>
      <dd className={cn({ [css.onSale]: isOnSale })}>
        <span className={css.price} data-test-id={testId('price')}>
          {prefix}
          {price?.string}
        </span>
        {isOnSale && (
          <>
            <span className={css.msrpLabelPrice}>
              <span className={css.msrpLabel}>MSRP: </span>
              <span className={css.originalPrice} data-test-id={testId('originalPrice')}>
                {msrp.string}
              </span>
            </span>
            {showPercentOff && (
              <span className={css.percentOff} data-test-id={testId('percentOff')}>
                {ProductUtils.getPercentOff({
                  originalPrice: msrp.string,
                  price: price.string
                })}
                % {percentOffText}
              </span>
            )}
          </>
        )}
      </dd>
    </>
  );
};

export default CardPrice;
